export default {
	login: {
		loginInTitle: 'User Login',
		loginAccount: 'Please input Account',
		loginPWD: 'Please input Password',
		code: 'Captcha',
		rememberMe: 'Remember me',
		AccountError: 'Please input a Right Account',
		PWError: 'Please input a password',
		codeError: 'Please input a Captcha',
		login: 'Login',
	}
}