export default {
	login: {
		loginInTitle: '用户登录',
		loginAccount: '请输入账号',
		loginPWD: '请输入密码',
		code: '验证码',
		rememberMe: '记住密码',
		AccountError: '请输入用户名',
		PWError: '请输入密码',
		codeError: '请输入验证码',
		login: '登录',

	}
}